import { AxiosHeaders } from "@/global";


export const config: {
    base_url: {
        base: string
    }

    result_code: number | string;
    default_headers: AxiosHeaders
    request_timeout: number
} = {
    base_url: {
        base: "/api",
    },


    result_code: 0,


    request_timeout: 60000 * 4, // four mins

    default_headers: 'application/json'
};

