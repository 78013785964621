
export default {
    name: "DisplayCard",
    props: {
        loading: {
            type: Boolean,
            default: false
        },


        link: {
            type: String
        },

        color: {
            type: String
        },

        width: {
            type: Number,
        },

        height: {
            type: Number
        },

        title: {
            type: String,
        },

        src: {
            type: String,
        },

        outlined: {
            type: String
        }
    }
}
