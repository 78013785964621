
import Vue from 'vue';
import Component from 'vue-class-component';


@Component({
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String
    }
  },

  computed: {
    isMedium: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  }
})


export default class Guide extends Vue {

  public closeGuide() {
    this.$emit('close');
  }
}
