
import Vue from 'vue';
import Component from 'vue-class-component';
import Typography from '@/components/Typography.vue';
import DisplayCard from "@/components/Card.vue";
import Burger from '@/components/Menu/Burger.vue';
import Dialog from '@/components/Dialog.vue';
import { getAllCategoriesApi } from "@/config/api/usercategory";
import ErrorResolver from "@/utils/ErrorResolver";
import MessageBox from "@/components/MessageBox.vue";
import LoadingDialog from '@/components/LoadingDialog.vue';
import ValidationRules from "@/utils/ValidationRules";
import { loginApi } from "@/config/api/login";
import Login from "@/pages/Home/components/Login.vue";


@Component({
  components: {
    Login,
    MessageBox,
    Typography,
    LoadingDialog,
    DisplayCard,
    Burger,
    Dialog
  },


  computed: {
    isMedium: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },


    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },


    smBtn: function () {
      if (this.isMedium) {
        this.smWidth = '100%';
      }

      this.smWidth;
    },

    pageHeight: function () {
      return document.body.scrollHeight; //get the page-current height
    },

    adjustImg: function () {
      if (!this.isMedium) {
        return '70px';
      }

      return this.hImg;
    },


    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },

    padLeft: function () {
      if (!this.isMedium) {
        return this.pLeft = '0px';
      }

      return this.pLeft;
    },

    checkWidth: function () {
      if (!this.isMedium) {
        return this.width = '40%'
      }


      return this.width
    },

    checkFont: function () {
      if (!this.isMedium) {
        return '40px';
      }

      return '60px';
    },

    checkCard: function () {
      if (!this.isMedium) {
        return this.infoCard = '300';
      }

      return this.infoCard;
    },


    checkmTop: function () {
      if (!this.isMedium) {
        return this.mTop = '200px';
      }

      return this.mTop;
    },
  }
})

export default class HomePage extends Vue {
  public dialogVisible: boolean = false;
  public isValid: boolean = false;
  public rules = ValidationRules;
  public duration: number = 300; //this is static
  public positionButton: string = '0';
  public infoCard: string = '400';
  public mTop: string = '140px';
  public hFont: string = '7.5em';
  public pLeft: string = '60px';
  public subText: string = '3em';
  public btn: string = '20%';
  public hImg: string = '60px';
  public smWidth: string = '20%';
  public text: string = '';
  public step: number = 1;


  public formData: Record<string, any> = {
    username: '',
    password: ''

  }

  public width: string = '20%';
  public loading: boolean = false;
  public mLeft: string = '10px';
  public items: any = []
  public message: string = '';
  public messageShow: boolean = false;
  public messageType: string = '';

  goTop() {
    return this.$vuetify.goTo(0, {
      easing: 'easeInCubic',
      duration: 700,
    });
  }

  gotBottom() {
    return this.$vuetify.goTo(this.pageHeight, {
      easing: 'easeInCubic',
      duration: 700
    })
  }

  showDialog() {
    this.dialogVisible = true;
  }

  getAll() {
    this.loading = true
    getAllCategoriesApi()
      .then(res => this.items = res.data)
      .catch(err => ErrorResolver.checkErrors(this, err))
      .finally(() => this.loading = false)
  }

  created() {
    this.getAll();
    this.positionButton;
  }
}
