import {useAxios} from "../axios/useAxios";
import {AxiosResponse} from "axios";

const request = useAxios();



export type UserLoginType = {
    username: string;
    password: string;
    jwt?: string;
    role?: 'admin' | 'staff'

    // staffId: string; 
    // password: string; 
}




export const loginApi = async (data: UserLoginType): Promise<AxiosResponse> => {
  return await request.post({
      url: '/api/auth/login',
      headersType: 'application/json',
      data,
    });
}

export const authGetCurrent = async (): Promise<AxiosResponse> => {
  return await request.get({
    url: '/api/auth/current-user',
  });
}
