import Storage from "@/hooks/useCache";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, HeadersDefaults } from "axios";
import { config } from "./config";
import Default from "@/config/config";


const { result_code, base_url } = config;

const BASE_URL = Default.BASE_URL;

export const service: AxiosInstance = axios.create({
    baseURL: BASE_URL, //api base url
    timeout: config.request_timeout //timeout
});


//rejection
service.interceptors.request.use((config: AxiosRequestConfig) => {
    let token = Storage.getAccessToken();
    if (token !== undefined && config.url !== undefined && !config.url.includes('/api/auth/login')) {
        config.headers!.Authorization = `Bearer ${token}`;
        config.withCredentials = true;
    }


    /**
     * @description write a better url formatter aganist the basurl
     */
    if (config.method === 'get' && config.params) {
        let url = config.url as string;
        url += '?';
        const keys = Object.keys(config.params)

        for (const key of keys) {
            if (config.params[key] !== void 0 && config.params[key] !== null) {
                url += `${key}=${encodeURIComponent(config.params[key])}&`
            }
        }

        url = url.substring(0, url.length - 1);
        config.params = {};
    }

    return config;

}, (error: AxiosError) => {
    //do something here
    // console.log(error.message);
    Promise.reject(error);
});


//response handler
service.interceptors.response.use((response: any) => {
    if (response.data.code === result_code || response.data === "") {
        return response.data == null;

    } else if (response.data.code === 500) {

    }

    return response;
}, (error: AxiosError) => {
    return Promise.reject(error);
});
