


import { useAppStore } from "@/store/modules/appStore";
import { mapStores } from "pinia";
import Vue, { ref, unref } from "vue";
import Component from "vue-class-component";



@Component({
    components: {},
    computed: {
        collapse: function() {
            return this.store.getCollapse; 
        },

        ...mapStores(useAppStore, {
            collapse: 'collapse'
        })
    }
    
})



export default class Burger extends Vue {
   store: any = useAppStore(); 
    toggle() {
        const collapsed = unref(this.collapse); 
        this.store.setCollapse(!collapsed); 
    
    }
}
