
  export default {
    name: "MessageBox",
    props: {
      show: Boolean,
      message: String,
      type: String, // success, warning or error
    },

    data: () => ({
      visible: false
    }),

    watch: {
      show: function(newVal){
        if(newVal){
          this.$toast.open({
            message: this.message,
            type: this.type,
            position: 'top-right'
          });

          this.close()
        }
      }
    },

    methods: {
      close(){
        // this.$props.show = false;
        this.$emit('close-message');
      }
    },

    computed: {
      icon: function(){
        return (this.type==='success')?'check-circle':(this.type === 'error' ? 'alert-circle' : (this.type === 'info' ? "information" : 'alert'))
      }
    }
  }
  