

import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
    },
    section: {

    },
    opacity: {
      type: String,

    },
    sectionActions: {
      type: String,
      default: 'Dialog'
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean
    }
  }
})



export default class Dialog extends Vue {
  public close(){
    this.$emit('close-dialog')
    this.$emit('dismiss')
  }
}

