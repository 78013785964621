

export default {
    name: "Text", 
    props: {
        title: {
            type: String, 
            default: 'Hello World'
        }
    }
}
