
import Vue from "vue";
import Component from "vue-class-component";
import Guide from "@/components/Guide.vue";
import ValidationRules from "@/utils/ValidationRules";
import {loginApi} from "@/config/api/login";
import Storage from "@/hooks/useCache";
import ErrorResolver from "@/utils/ErrorResolver";
import LoadingDialog from "@/components/LoadingDialog.vue";
import MessageBox from "@/components/MessageBox.vue";

@Component({
  components: {MessageBox, LoadingDialog, Guide},
  props: {
    show: Boolean
  }
})
export default class Login extends Vue {

  public step: number = 1;
  public isValid: boolean = false;
  public rules = ValidationRules;
  public loading: boolean = false;
  public items: any = []
  public message: string = '';
  public messageShow: boolean = false;
  public messageType: string = '';
  public dialogVisible: boolean = false;
  public formData: Record<string, any> = {
    username: '',
    password: ''
  }

  public close() {
    this.$emit('dismiss')
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  public signin() {
    this.loading = true;
    loginApi(this.formData).then((res) => {
      // this.dialogVisible = false;
      Storage.setAccessToken(res.data.jwt);
      window.location.href = '/dashboard';
    }).catch(err => {
      console.log(err)
      ErrorResolver.checkErrors(this, err)
    })
      .finally(() => {
        this.loading = false;
      });
  }
}
