
import Vue from "vue";
import { mapState } from "pinia"; 
import { useAppStore } from "@/store/modules/appStore";
import Component from "vue-class-component";


@Component({
    components: {}, 


    computed: {
        ...mapState(useAppStore, ['collapse'])
    }
})


export default class Sidebar extends Vue {
    // public store: any = useAppStore(); 

    store: any = useAppStore(); 

    closePanel() {
       return this.store.getCollapse; 
    }


    created() {
       // this.store.collapse = false
    }
}

