import {AxiosConfig} from "@/global";
import {AxiosPromise} from "axios";
import {service} from ".";
import {config} from "./config"

const {default_headers} = config

const request = (options: AxiosConfig) => {
  // process queries
  const {url, method, data, headersType, responseType, params, token} = options
  return service({
    url,
    method,
    data,
    params,
    responseType,
    headers: {
      'Content-Type': headersType || default_headers
    }
  })
}


function getFn<T = any>(options: AxiosConfig): AxiosPromise<T> {
  return request({method: 'get', ...options})
}


function postFn<T = any>(options: AxiosConfig): AxiosPromise<T> {
  return request({method: 'post', ...options});
}


function deleteFn<T = any>(options: AxiosConfig): AxiosPromise<T> {
  return request({
    method: 'delete',
    url: options.url,
    headersType: options.headersType,
    params: options.params,
    responseType: options.responseType,
    data: options.data,
    token: options.token
  });
}


function putFn<T = any>(options: AxiosConfig): AxiosPromise<T> {
  return request({method: 'put', ...options});
}


export const useAxios = () => {
  return {
    get: getFn,
    post: postFn,
    delete: deleteFn,
    put: putFn
  }
}

export const processParams = (params: any) => {
  let u: String = '';
  for (let key of Object.keys(params)){
    // if it is a boolean
    if(params[key] != null && params[key] != undefined && (typeof params[key] == 'boolean' || params[key].constructor.toString().indexOf("Boolean") > -1)){
      // just add it
      if(u === ''){
        u = `?${key}=${params[key]}`
      } else{
        u = `${u}&${key}=${params[key]}`
      }
    } else{
      // if it is defined
      if(params[key]){
        if(u === ''){
          u = `?${key}=${params[key]}`
        } else{
          u = `${u}&${key}=${params[key]}`
        }
      }
    }
  }


  return u;
}
